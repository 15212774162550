import { FONT_TEMPLATE } from "../../../../data/captions";
import { defaultJsonTemplate } from "../../../../data/defaultJsonTemplate";
import { prefetch } from "remotion";
import { IS_FREE_PLATFORM } from "../../../../config";
import {
  categorisedMusicList,
  categorisedMusicListTwo,
} from "../../../../data/music";
import { generateUniqueId } from "../../core";
import { aiTextToSoundEffect, generateAiAudio } from ".././audioGeneration";
import addLogo from ".././helperFunctions/addLogo";
import { logo, logoCTA } from "../../../../data/logo";
import {
  animationFunc,
  animationFuncCustomeOne,
  animationFuncCustomeOne3D,
} from ".././helperFunctions/animations";
import {
  aiImagesAPI,
  generateDalleImage,
  getGettyImages,
  getGettyVideos,
  getGettyVideosTest,
  getPexelsVideos,
} from ".././helperFunctions/mediaSearch";
import { generateSubtitles } from "../../captions";
import { getImageSize } from "polotno/utils/image";
import { useGlobalContext } from "../../../../context/globalContext";
import { fitTextwithBreaks } from "../../polotno/resizing";
import { icons } from "antd/es/image/PreviewGroup";
import {
  fetchReplicas,
  generateAiAvatarVideo,
} from "../helperFunctions/tavusApi";
import { calculateBottomLeftPosition } from "../../calculatePositions";
import { generateSnapShot } from "../helperFunctions/webSnapShot";

export async function ScriptToCanvasBurstVersion2(
  store: any,
  scriptData: any,
  industry: any,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string,
  videoMediaType: string,
  color: any,
  userDetails: any,
  setSnapShotsTasks: any
) {
  // Load default Template if free platform
  // if (IS_FREE_PLATFORM === 'true') {
  store.loadJSON(defaultJsonTemplate);
  // }

  // Set default durations to 3.5 seconds
  store.pages.map((page: any) => {
    page.set({ duration: 3500 });
  });
  // if(!userDetails?.purpose?.cta){
  //   icon=""
  // }
  // Extract scriptPages from scriptData

  const scriptPages = scriptData.data;

  let totalImageLength = 0;
  // Calculate the total length of images across all objects and getting total number of pages that need to be rendered
  for (const pages of scriptPages) {
    totalImageLength += pages.images.length;
  }
  // Clone the store pages based on the total image length
  for (let i = store.pages.length; i < totalImageLength; i++) {
    store.pages[i % 2].clone();
  }

  const ReplicasTavus = await fetchReplicas({
    limit: 200,
    page: 1,
    verbose: true,
    // replica_type: "",
    replica_ids: "",
  });
  //********** Initialise loader and steps **********//
  const totalIterations = store.pages.length * 2.25; // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations;

  let currentProgress = 0;

  setProgressBarPercent(Math.round(currentProgress));
  //************************************************//

  //********** Initialise scriptPages Index to parse through each section / Ad Block Type **********//
  let scriptIndex = 0;
  let subIndex = 0;
  let assetIndex = 0;
  let assetIndexLength = 0;
  let audioDuration:any
  //********** Loop through all pages and generate + add audio and media **********//
  for (let index = 0; index < store.pages.length; index++) {
    assetIndexLength = scriptPages[index]?.assets?.length || 0;
    //********** Generate Ai Voice Audio **********//
    if(scriptPages[scriptIndex]?.voiceOver?.length>0){
      audioDuration = await generateAiAudio(
        scriptPages[scriptIndex],
        store,
        scriptIndex,
        setScriptDetails,
        setProgressBarPercent,
        currentProgress,
        progressIncrement,
        scriptPages.length,
        industry,
        videoStyle,
        userDetails,
        selectedSize,
        scriptPages
      );
    }
    

    //********** Get the Section from the script **********//
    const scriptPage = scriptPages[scriptIndex];

    // ********** Calculate the duration of each Image / Page by equally dividing the audio duration by length of images inside this scriptPage ********** //
    // const pageDuration = audioDuration?.newDuration|| 3500 / scriptPage.images.length;
    
    const pageDuration = (audioDuration?.newDuration || 3500) / (scriptPage.images?.length || 1);

    // await aiTextToSoundEffect(scriptPages[0]?.soundEffect || 'Drum beats', 20)
    //********** Loop all images / relevant pages for each script block **********//
    for (let j = 0; j < scriptPage.images.length; j++) {
      const storeScriptIndex = scriptIndex;
      const storeImageIndex = j;

      // Get all children of the page
      const children = store.pages[index].children;

      //
      const textElements = children.filter(
        (child: any) => child.type === "text"
      );

      const sortedTextElementsByFont = textElements
        .filter(() => {
          return true;
        })
        .sort((a: any, b: any) => b.fontSize - a.fontSize);
      if (sortedTextElementsByFont.length > 0) {
        const element = store.getElementById(sortedTextElementsByFont[0].id);
        const fontSize = fitTextwithBreaks(
          element.width,
          element.height,
          element.text,
          element.fontFamily,
          element.lineHeight,
          element.fontSize
        ); // Calculate the new font size
        await element.set({
          // text: scriptPages[scriptIndex].textOverlay,
          // text:'',
          fontSize: fontSize,
          y: store.height - 300,
          visible: false,
        });
      }

      // Get Image object from script
      const pageImage = scriptPage.images[j];

      // Get the first search query set searchIndex for parsing later for the image
      const searchIndex = 0;
      let searchQuery =
        pageImage?.searchQueries?.[searchIndex] ||
        pageImage?.images?.[searchIndex]?.searchQueries?.[0] ||
        "";

      // Set Initial Orientation based on size input
      const orientations = selectedSize === "9:16" ? "vertical" : "horizontal";

      // Set Initial VideoElementID to empty string
      let mediaElementID = "";

      // Set passed to false to determine if all search queries are exhausted on getty and we need to move to Pexels
      // let passed = false;

      // Find the largest mediaEkement
      const videoElements = children.filter(
        (child: any) => child.type === "image"
      );
      if (videoElements.length > 0) {
        const largestVideo = videoElements.reduce(
          (prev: any, current: any) =>
            prev.width * prev.height > current.width * current.height
              ? prev
              : current,
          videoElements[0]
        );
        mediaElementID = largestVideo.id;
      }
      //-----------------------------New logic for new story board----------------------------//

      const type = pageImage?.type;
      const url = pageImage?.mediaUrl;
      const gettyId = pageImage?.gettyId;
      const searchArrayindex = Math.floor(
        Math.random() * pageImage?.searchQueries?.length ||
          pageImage?.images?.[0]?.searchQuery
      );

      if (scriptPages[scriptIndex]?.aiAvatarProps?.noAvatar) {
        const videoId = generateUniqueId();

        if (!url) {
          searchQuery =
            pageImage?.searchQueries?.[searchArrayindex] ||
            pageImage?.images?.[0]?.searchQuery?.[0] ||
            scriptPage?.voiceOver?.slice(0, 20) || scriptPages[0]?.searchQueries[0];
          const gettyVideoURL: any = await getGettyVideosTest(
            searchQuery,
            orientations,
            userDetails?.purpose?.videotechniques || []
          );
          store.pages[index].addElement({
            type: "video",
            src: gettyVideoURL.uri,
            x: -25,
            y: -25,
            width: store.width + 50,
            height: store.height + 50,
            id: videoId,

            custom: {
              gettyId: gettyId,
              voiceOver: scriptPages[scriptIndex].voiceOver,
              aiAvatar: false,
            },
            // animations:animationFunc(),
          });
        } else {
          store.pages[index].addElement({
            type: type,
            src: url,
            x: -25,
            y: -25,
            width: store.width + 50,
            height: store.height + 50,
            id: videoId,

            custom: {
              gettyId: gettyId,
              voiceOver: scriptPages[scriptIndex].voiceOver,
              aiAvatar: false,
            },
            // animations:animationFunc(),
          });
        }

        store.deleteElements([mediaElementID]);
        store.getElementById(videoId).set({
          animations: animationFuncCustomeOne(pageDuration),
        });
      } else {
        const isBackgroundWithMedia =
          scriptPages[scriptIndex]?.aiAvatarProps?.avatarWithBg;
        const isBackgroundWithWebsite =
          scriptPages[scriptIndex]?.aiAvatarProps?.avatarWithWebsite;
        const backgroundSourceUrl = scriptPages[scriptIndex]?.aiAvatarProps
          ?.avatarWithBg
          ? scriptPages[scriptIndex]?.aiAvatarProps?.mediaUrl
          : "";
        const backgroundWebiste = scriptPages[scriptIndex]?.aiAvatarProps
          ?.avatarWithWebsite
          ? scriptPages[scriptIndex]?.aiAvatarProps?.websiteUrl ||
            userDetails?.purpose?.productUrl
          : "";
        // const avatarResponse = await generateAiAvatarVideo(
        //   audioDuration?.audioUrl || "",
        //   userDetails?.purpose?.avatarReplicaId,
        //   "",
        //   "",
        //   scriptPages[scriptIndex].voiceOver,
        //   scriptPages[scriptIndex]?.aiAvatarProps?.scriptAvatar
        // );

        const initialAvatarUrl = ReplicasTavus.find(
          (replica: any) =>
            replica.replica_id == userDetails?.purpose?.avatarReplicaId
        );
      
        if (isBackgroundWithMedia || isBackgroundWithWebsite) {
          const videoIdMain = generateUniqueId();
          const videoChildId = generateUniqueId();
          if (isBackgroundWithMedia) {
            store.pages[index].addElement({
              src: backgroundSourceUrl || "",
              type: type,
              id: videoChildId,
              duration: pageDuration,
              x: -25,
              y: -25,
              width: store.width + 50,
              height: store.height + 50,

              borderRadius: 10,
              custom: {
                gettyId: videoChildId,
                mainElement: false,
                aiAvatar: false,
                scriptIndex: index,
              },
            });
            if (scriptPages[scriptIndex]?.voiceOver?.length > 0) {
              store.pages[index].addElement({
                src: initialAvatarUrl?.thumbnail_video_url || "",
                type: "video",
                id: videoIdMain,
                duration: pageDuration,
                x: -1,
                y: store.height - store.height / 3,
                width: store.width / 3,
                height: store.height / 3,
                cornerRadius: 20,

                custom: {
                  gettyId: videoIdMain,
                  mainElement: true,
                  aiAvatar: true,
                  scriptIndex: index,
                  bg:true
                },
              });

            store.getElementById(videoIdMain).set({
              animations: animationFuncCustomeOne(store.pages[index].duration),
            });
          }

            store.deleteElements([mediaElementID]);
          } else if (isBackgroundWithWebsite) {
           
            const position = await calculateBottomLeftPosition(
              initialAvatarUrl?.thumbnail_video_url,
              store.width + 50,
              store.height + 50,
              "video"
            );
            const snapShotResponse = await generateSnapShot(
              backgroundWebiste,
              orientations
            );
            console.log(snapShotResponse);
            const newTask = {
              elementId: videoChildId,
              status: snapShotResponse.status,
              videoUrl: "",
              scriptIndex: scriptIndex,
              taskId: snapShotResponse.task_id,
            };
            setSnapShotsTasks((prev: any) => [...prev, newTask]);
            store.pages[index].addElement({
              src: `https://d19nwjvgyqcpr7.cloudfront.net/assets/0c625434-df57-4390-a2e8-d6e3bec2a152/videos/3517f458-f9ec-40be-90f4-446d4327db46/video.mp4`, //callback url before getting output from snapshot api
              type: "video",
              id: videoChildId,
              duration: pageDuration,
              x: -25,
              y: -25,
              width: store.width + 50,
              height: store.height + 50,
              cornerRadius: 20,

              custom: {
                gettyId: videoChildId,
                mainElement: false,
                aiAvatar: false,
                scriptIndex: index,
              },
            });
            //-------website scrolling record-------//
            if (scriptPages[scriptIndex]?.voiceOver?.length > 0) {
              store.pages[index].addElement({
                src: initialAvatarUrl?.thumbnail_video_url || "",
                type: "video",
                id: videoIdMain,
                duration: pageDuration,
                x: -1,
                y: store.height - store.height / 3,
                width: store.width / 3,
                height: store.height / 3,
                cornerRadius: 20,
                borderRadius: 10,
                custom: {
                  gettyId: videoIdMain,
                  mainElement: true,
                  aiAvatar: true,
                  scriptIndex: index,
                  bg:true
                },
              });

            //------------------------
            store.getElementById(videoIdMain).set({
              animations: animationFuncCustomeOne(store.pages[index].duration),
            });
          }

          }
          store.deleteElements([mediaElementID]);
        } else {
          const videoId = generateUniqueId();
          if (scriptPages[scriptIndex]?.voiceOver?.length > 0) {
            store.pages[index].addElement({
              src: initialAvatarUrl?.thumbnail_video_url || "",
              type: "video",
              id: videoId,
              duration: pageDuration,
              x: -25,
              y: -25,
              width: store.width + 50,
              height: store.height + 50,
              cornerRadius: 20,

              custom: {
                gettyId: videoId,
                mainElement: false,
                aiAvatar: true,
                scriptIndex: index,
              },
            });
          } else {
         
            searchQuery =
              pageImage?.searchQueries?.[searchArrayindex] ||
              pageImage?.images?.[0]?.searchQuery?.[0] ||
              scriptPage?.voiceOver?.slice(0, 20) || scriptPages[0]?.searchQueries[0];
            const gettyVideoURL: any = await getGettyVideosTest(
              searchQuery,
              orientations,
              userDetails?.purpose?.videotechniques || []
            );
            store.pages[index].addElement({
              type: "video",
              src: gettyVideoURL.uri,
              x: -25,
              y: -25,
              width: store.width + 50,
              height: store.height + 50,
              id: videoId,

              custom: {
                gettyId: gettyId,
                voiceOver: scriptPages[scriptIndex].voiceOver,
                aiAvatar: false,
              },
              // animations:animationFunc(),
            });
          }

          store.deleteElements([mediaElementID]);

          store.getElementById(videoId).set({
            animations: animationFuncCustomeOne(store.pages[index].duration),
          });
        }
      }
      store.pages[index].set({
        duration: pageDuration,
        custom: {
          ...store.pages[index].custom,
          searchQuery: searchQuery,
          searchQueries: pageImage?.images?.[0].searchQueries,
          imageIndex: storeImageIndex,
          scriptIndex: storeScriptIndex,
        },
      });
      //--------------------------------------------------------------------------------------//

      currentProgress += progressIncrement;
      setProgressBarPercent(Math.round(currentProgress));

      currentProgress += progressIncrement;
      setProgressBarPercent(Math.round(currentProgress));

      // Add logo if not free platform
      // https://adgen-statics.s3.ap-south-1.amazonaws.com/adgen-fe/new_logo.png
      if (IS_FREE_PLATFORM === "false") {

        if(icon){
          const { waitUntilDone } = prefetch(icon, {
            method: "blob-url",
            contentType: "image/png",
          });
          await waitUntilDone();
          // if (userDetails?.purpose?.cta) {
            if (store.pages.length - 1 === index) {
              let { width, height } = await getImageSize(icon);
              const scaleFactor = selectedSize === "9:16" ? 0.4 : 0.2;
              let scale = Math.min(
                store.width / width,
                store.height / height,
                scaleFactor
              );
  
              // Limit width to 250 pixels
              if (width * scale > 100) {
                scale *= 100 / (width * scale); // Adjust scale to fit within 250 width
              }
              // Ensure height is scaled proportionally
              height *= scale;
              width *= scale;
              store.pages[index].addElement({
                ...logoCTA,
                id: "cta" + index.toString(),
                src: `${icon}`,
  
                // visible: false,
                x:
                  selectedSize === "9:16"
                    ? store.width - width - 150
                    : store.width - width - 185,
                y: store.height - 400,
                width: selectedSize === "16:9" ? width + 80 : width,
                height: selectedSize === "16:9" ? height + 50 : height,
                opacity: userDetails?.purpose?.showLogo ? 1 : 0,
                visible: userDetails?.purpose?.showLogo,
              });
            } else {
              if (icon)
                await addLogo(
                  icon,
                  index,
                  selectedSize,
                  store,
                  userDetails?.purpose?.showLogo
                );
            }
          // }
        }
       
      }

      //********* Move onto the next page manually so we can loop through all images inside one script block *********//
      // j !== scriptPage.images.length - 1 && index++
      // j === scriptPage.images.length - 1 && scriptIndex < scriptPages.length - 1 && scriptIndex++ && subIndex=0
      if (j !== scriptPage.images.length - 1) {
        index++;
      }

      if (
        j === scriptPage.images.length - 1 &&
        scriptIndex < scriptPages.length - 1
      ) {
        scriptIndex++;
        subIndex = 0;
      }

      if (assetIndex <= assetIndexLength - 1) {
        assetIndex++;
      } else {
        assetIndex = 0;
      }
    }
  }

  const duration = 60;
  currentProgress += progressIncrement;
  const thumbnailImagePrompt = scriptPages[0]?.voiceOver;
  const thumbnailImage = await generateDalleImage(
    thumbnailImagePrompt,
    selectedSize === "9:16" ? "vertical" : "horizontal"
  );
  setProgressBarPercent(Math.round(currentProgress));
  let musicUrl = "";
  let musicName = "";
  const style = localStorage.getItem("style") || "Simple";
  const Prompt = localStorage.getItem("Prompt");

  try {
    if (Prompt === "false") {
      if (categorisedMusicListTwo[style]) {
        const musicArrLength =
          categorisedMusicListTwo[style][selectedSize].length;
        const randomIndex = Math.floor(Math.random() * musicArrLength);
        musicUrl =
          categorisedMusicListTwo[style][selectedSize][randomIndex].url;
        musicName =
          categorisedMusicListTwo[style][selectedSize][randomIndex].name;
      }
    } else {
      if (categorisedMusicListTwo["Prompt"]) {
        const musicArrLength =
          categorisedMusicListTwo["Prompt"][selectedSize].length;
        const randomIndex = Math.floor(Math.random() * musicArrLength);
        musicUrl =
          categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].url;
        musicName =
          categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].name;
      }
    }
  } catch {
    const musicArrLength =
      categorisedMusicListTwo["Simple"][selectedSize].length;
    const randomIndex = Math.floor(Math.random() * musicArrLength);
    musicUrl = categorisedMusicListTwo["Simple"][selectedSize][randomIndex].url;
    musicName =
      categorisedMusicListTwo["Simple"][selectedSize][randomIndex].name;
  }

  const backgroundScript = {
    url: musicUrl,
    duration: 30,
    industry: industry,
    name: musicName,
    backgroundVolume: 0.03,
  };

  // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
  await setBackgroundMusic(backgroundScript);

  ////*********  Generate subtitles by default if free version *********//
  await generateSubtitles(
    "Poppins",
    FONT_TEMPLATE[0].wordsPerLine,
    30,
    store,
    FONT_TEMPLATE[0].wordsPerLine,
    FONT_TEMPLATE[0],
    true,
    store.height - store.height * 0.4,
    () => {
      true;
    },
    setScriptDetails
  );

  localStorage.setItem("thumbnailImage", thumbnailImage);
  await setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript };
    return {
      ...updatedScript,
      backgroundMusic: backgroundScript,
      thumbnailImage: thumbnailImage,
      transitionSound: false,
      audioFade: true,
      aiVoiceSpeed: 1,
    };
  });
  console.log(store.toJSON());
  store.selectPage(store.pages[0].id);
  store.history.clear();
}
